<template>
  <DialogForm
    fixed-header
    flat
    :title="title"
    :loading="runningAction"
    :data-test-id="'providerDetail_' + title"
  >
    <template #actions="{ loading }">
      <v-btn
        class="ml-2"
        data-test-id="savePaymentProviderBtn"
        :outlined="!isDirty"
        :color="hasViolation ? 'error' : 'green'"
        :disabled="loading"
        @click="savePaymentProvider"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          :color="isDirty ? 'white' : 'error'"
          alignment="left"
        />
        Save
      </v-btn>
    </template>
    <template #form="{ loading }">
      <v-form ref="form">
        <DataEditor
          v-if="providerModel.schema"
          :uimodel="providerModel"
          :disabled="loading"
          @input="updateProvider"
          @restore="restoreProvider"
        />
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import configServiceMixin from "mixins/config-service-mixin";

import DialogForm from "components/common/templates/DialogForm.vue";
import DataEditor from "components/configuration/data/editors/DataEditor";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
export default {
  mixins: [configServiceMixin],

  provide() {
    return {
      getSource: this.getSource,
      getViolations: this.getViolations,
    };
  },

  components: {
    DialogForm,
    DataEditor,
    ViolationAlert,
  },

  props: {
    provider: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      data: null,
      savedData: null,
      mergedData: null,
      schema: null,
      sources: [],
      uimodel: null,
      runningAction: false,
      violation: null,
      isDirty: false,
    };
  },

  watch: {
    data: {
      handler: function (data) {
        this.isDirty = !this.$isEqual(data, this.savedData);
      },
      deep: true,
    },
  },

  mounted() {
    this.loadProviderData();
  },

  methods: {
    async loadProviderData() {
      try {
        this.runningAction = true;

        const mergedSchemaRes =
          await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern
          );

        this.schema = mergedSchemaRes?.schema;
        const mergedDataRes =
          await this.$store.$coreApi.coreConfigurationApi.getMergedData(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern,
            null,
            {
              query: "withInheritanceSources=true",
            }
          );

        this.mergedData = mergedDataRes?.data;
        this.sources = mergedDataRes?.sources ?? [];

        //retrieve data of this domain
        const fullData =
          await this.$store.$coreApi.coreConfigurationApi.getData(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern,
            {
              includeParents: false,
            }
          );

        const data = fullData?.[0]?.data;
        //deep clone of data for usage and restore
        this.data = this.$cloneObject(data);
        this.savedData = this.$cloneObject(data);
      } finally {
        this.runningAction = false;
      }
    },

    async savePaymentProvider() {
      try {
        this.runningAction = true;
        this.violation = null;
        if (!this.validate()) {
          this.violation = {
            message: "At least one input field is invalid",
          };
          return;
        }

        const successMsg =
          `Payment provider <strong>` + this.provider.id + `</strong> saved`;

        const res = await this.upsertData(
          this.keySpace,
          this.keyPattern,
          this.data,
          successMsg
        );

        if (res?.status !== 200) {
          if (res?.violation) {
            this.violation = res.violation;
          }
          return;
        }

        await this.loadProviderData();
      } finally {
        this.runningAction = false;
      }
    },

    validate() {
      const form = this.$refs.form;
      return this.$validateVForm(form);
    },

    updateProvider(data) {
      this.data = this.$cloneObject(data);
    },

    restoreProvider() {
      this.data = this.$cloneObject(this.savedData);
    },
  },

  computed: {
    title() {
      return this.provider.id;
    },

    keySpace() {
      return this.provider.keySpace;
    },

    keyPattern() {
      return this.provider.key;
    },

    hasViolation() {
      return !!this.violation;
    },

    providerModel() {
      const data = this.$cloneObject(this.data);
      const savedData = this.$cloneObject(this.savedData);
      const mergedData = this.$cloneObject(this.mergedData);
      let schema = this.schema;

      let providerSchema = schema?.properties?.provider;
      if (providerSchema) {
        if (!providerSchema?.dataUI) {
          this.$set(providerSchema, "dataUI", {});
        }
        this.$set(providerSchema.dataUI, "readonly", true);
      }

      const uimodel = {
        schema: this.schema,
        data,
        savedData,
        mergedData,
        path: "$",
        inheritable: true,
        editable: true,
        domain: this.selectedDomain,
        dataLevel: 0,
        isRoot: true,
        keySpace: this.keySpace,
        key: this.keyPattern,
      };
      return uimodel;
    },
  },
};
</script>

<style scoped>
</style>