<template>
  <div class="payment-providers-overview">
    <v-data-table
      disable-pagination
      hide-default-footer
      class="payment-providers-table"
      fixed-header
      single-select
      show-expand
      multi-sort
      data-test-id="paymentProviderTable"
      :options.sync="options"
      :items-per-page="-1"
      :height="tableHeight"
      :items="paymentProviders"
      :headers="headers"
      :loading="runningAction"
      :item-class="getItemClass"
      :expanded.sync="expanded"
    >
      <!-- eslint-disable-next-line -->
      <template #group.header="{ group, isOpen, toggle }">
        <td :colspan="headers.length" :data-test-id="'group_header_' + group">
          <div class="d-flex align-center pa-1">
            <v-icon
              @click="toggle"
              :data-test-id="'group_' + group + '_toogle_btn'"
            >
              {{ isOpen ? "mdi-chevron-down" : "mdi-chevron-right" }}
            </v-icon>
            <div class="ml-3 font-weight-medium text-truncate">
              {{ group }}
            </div>
          </div>
        </td>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          icon
          v-if="isExpanded"
          @click="expand(false)"
          :data-test-id="'payment_provider_' + item.id + '_hide_btn'"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          icon
          v-else
          @click="expand(true)"
          :data-test-id="'payment_provider_' + item.id + '_expand_btn'"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1" class="pa-3">
          <!-- TODO: Provider detail -->
          <PaymentProvider :provider="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import paymentMixin from "mixins/payment-mixin";
import configServiceMixin from "mixins/config-service-mixin";
import sortQueryMixin from "mixins/sort-query-mixin";

import PaymentProvider from "./PaymentProvider";
export default {
  mixins: [configServiceMixin, paymentMixin, sortQueryMixin],

  inject: ["getEditorHeight"],

  components: {
    PaymentProvider,
  },

  data() {
    return {
      runningAction: false,
      paymentProviders: [],
      expanded: [],
      options: {
        sortBy: ["id"],
        sortDesc: [false],
      },
    };
  },

  mounted() {
    const routeQuery = this.$route.query;
    let sort = routeQuery.sort;

    if (sort) {
      const options = this.parseSortQueryToOptions(sort);
      this.options = Object.assign(this.options, options);
    }
    this.loadProviders();
  },

  watch: {
    options(options) {
      let query = Object.assign({}, this.$route.query);
      const sort = this.parseOptionsToSortQuery(options);
      if (!sort) this.$delete(query, "sort");
      else this.$set(query, "sort", sort);

      this.$router.push({
        ...this.$route,
        query,
      });
    },
  },

  methods: {
    async loadProviders() {
      try {
        this.runningAction = true;
        await this.loadPaymentProviders(true);
      } finally {
        this.runningAction = false;
      }
    },

    getItemClass(providerModel) {
      let itemClass = ["payment-provider-" + providerModel.id];
      return itemClass.join(" ");
    },
  },

  computed: {
    headers() {
      return [{ text: "Provider", value: "id", class: "provider-header" }];
    },

    tableHeight() {
      return this.getEditorHeight() - this.$vToolbarMarginBottom;
    },
  },
};
</script>

<style scoped>
.payment-providers-overview {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-start;
  padding-top: 12px;
}
</style>