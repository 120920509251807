var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-providers-overview"},[_c('v-data-table',{staticClass:"payment-providers-table",attrs:{"disable-pagination":"","hide-default-footer":"","fixed-header":"","single-select":"","show-expand":"","multi-sort":"","data-test-id":"paymentProviderTable","options":_vm.options,"items-per-page":-1,"height":_vm.tableHeight,"items":_vm.paymentProviders,"headers":_vm.headers,"loading":_vm.runningAction,"item-class":_vm.getItemClass,"expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":_vm.headers.length,"data-test-id":'group_header_' + group}},[_c('div',{staticClass:"d-flex align-center pa-1"},[_c('v-icon',{attrs:{"data-test-id":'group_' + group + '_toogle_btn'},on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-down" : "mdi-chevron-right")+" ")]),_c('div',{staticClass:"ml-3 font-weight-medium text-truncate"},[_vm._v(" "+_vm._s(group)+" ")])],1)])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(isExpanded)?_c('v-btn',{attrs:{"icon":"","data-test-id":'payment_provider_' + item.id + '_hide_btn'},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","data-test-id":'payment_provider_' + item.id + '_expand_btn'},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":_vm.headers.length + 1}},[_c('PaymentProvider',{attrs:{"provider":item}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }